<template>
  <div>
    <v-card>
      <v-card-title>
        <v-container class="mb-5 pb-0">
          <v-row>
            <DialogBatchMove :selected="selected" @update="getData()">
              <template #activator="{on}">
                <v-btn v-on="on" class="primary" min-width="250" :disabled="!selected.length">Přesunout</v-btn>
              </template>
            </DialogBatchMove>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="aBtn--primaryInv ml-auto"
                    icon
                    @click="showFilters = !showFilters"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Zobrazit filtry</span>
            </v-tooltip>
          </v-row>
          <v-row v-if="showFilters">
            <v-col cols="12">
              <v-divider/>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-checkbox
                  v-model="search.buyout"
                  label="Výkup"
                  class="mt-2"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                  outlined
                  light
                  :value="formattedDate(search.dateFrom)"
                  class="formInput--datePicker"
                  label="Datum od..."
                  :readonly="true"
                  validate-on-blur
              >
                <template #append>
                  <v-icon @click="search.dateFrom = '' ">mdi-minus-circle</v-icon>
                  <DatePicker v-model="search.dateFrom"/>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                  outlined
                  light
                  :value="formattedDate(search.dateTo)"
                  class="formInput--datePicker"
                  label="Datum do..."
                  :readonly="true"
                  validate-on-blur
              >
                <template #append>
                  <v-icon @click="search.dateTo = '' ">mdi-minus-circle</v-icon>
                  <DatePicker v-model="search.dateTo"
                              :showCurrent="search.dateFrom !== '' ? search.dateFrom:undefined"/>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="d-flex ga-4">
              <v-text-field
                  prepend-inner-icon="mdi-magnify"
                  label="Vyhledat..."
                  outlined
                  v-model="search.fulltext"
                  @keydown.enter="handleSearch"
              >
                <template #append>
                  <v-tooltip top>
                    <template #activator="{on}">
                      <v-icon v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>Pro vyhledávání stiskněte enter</span>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-btn class="primary" large @click="handleSearch()">Vyhledat</v-btn>
              <v-btn class="aBtn--primaryInv" large @click="handleResetSearch()">Výchozí</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
          v-model="selected"
          :headers="headers"
          :items="batches"
          :loading="grid.loading"
          :options.sync="options"
          :server-items-length="this.grid.count"
          :items-per-page="grid.defaultPageSize"
          class="vDataTable--dense elevation-2"
          :footer-props="{
            'items-per-page-text':'Počet výkupů na stránce',
            'items-per-page-options':grid.itemsPerPageOptions,
            'rowsPerPage':grid.defaultPageSize
          }"
          :show-select="true"
          show-expand
          :expanded.sync="expanded"
          :item-class="row_classes"
      >


        <template #[`header.type_batch.description`]="{ header }">
          <v-select
              :label="header.text"
              dense
              single-line
              :append-icon="search.batch_type ? '' : '$dropdown'"
              open-on-clear
              v-model="search.batch_type"
              :items="batchTypes"
              clearable
              :item-text="(value) => value.description"
              :item-value="(value) => value.id"
          />
        </template>


        <template #[`header.type_commodity.description`]="{ header }">
          <v-select
              :label="header.text"
              dense
              single-line
              :append-icon="search.commodity ? '' : '$dropdown'"
              open-on-clear
              v-model="search.commodity"
              :items="typeCommodityList"
              clearable
              :item-text="(value) => value.description"
              :item-value="(value) => value.id"
          />
        </template>


        <template #[`header.commodity_purity.purity`]="{ header }">
          <v-select
              :label="header.text"
              dense
              single-line
              :append-icon="search.purity ? '' : '$dropdown'"
              open-on-clear
              v-model="search.purity"
              :items="commodityPurities"
              clearable
              :item-text="(value) => value.full_description"
              :item-value="(value) => value.id"
          />
        </template>


        <template #[`header.last_state_description`]="{ header }">
          <v-select
              :label="header.text"
              dense
              single-line
              :append-icon="search.state ? '' : '$dropdown'"
              open-on-clear
              v-model="search.state"
              :items="states"
              clearable
              :item-text="(value) => value.description"
              :item-value="(value) => value"
          />
        </template>


        <template #[`header.reviewed`]="{ header }">
          <v-select
              :label="header.text"
              dense
              single-line
              :append-icon="search.reviewed ? '' : '$dropdown'"
              open-on-clear
              v-model="search.reviewed"
              :items="[ {value: true, text: 'Ano'}, {value: false, text: 'Ne'}]"
              clearable
          />
        </template>


        <template #[`header.exported`]="{ header }">
          <v-select
              :label="header.text"
              dense
              single-line
              :append-icon="search.exported ? '' : '$dropdown'"
              open-on-clear
              v-model="search.exported"
              :items="[ {value: true, text: 'Ano'}, {value: false, text: 'Ne'}]"
              clearable
          />
        </template>

        <template #[`header.boss_voucher_send_count`]="{ header }">
          <v-select
              :label="header.text"
              dense
              single-line
              :append-icon="search.boss_voucher_send ? '' : '$dropdown'"
              open-on-clear
              v-model="search.boss_voucher_send"
              :items="[ {value: true, text: 'Ano'}, {value: false, text: 'Ne'}]"
              clearable
          />
        </template>


        <template v-slot:expanded-item="{headers,  item }">
          <td :colspan="headers.length">
            <v-simple-table dense>
              <template #default>
                <tbody>
                <tr v-for="(buyout, i) in item.buyouts" :key="i">
                  <td>
                    <span style="padding: 0 32px 0 83px;">{{ formattedDate(buyout.x_created) }}</span>
                    <span>{{ buyout.number }}</span>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>

        <template #[`item.reviewed`]="{item}">
          <div
              v-if="item.reviewed"
              class="gLabel gLabel--success"
          >
            <v-icon small>$circle-check</v-icon>
            Ano
          </div>
          <div
              v-else
              class="gLabel gLabel--error"
          >
            <v-icon small>$circle-exclamation</v-icon>
            Ne
          </div>
        </template>
        <template #[`item.exported`]="{item}">
          <div
              v-if="item.exported"
              class="gLabel gLabel--success"
          >
            <v-icon small>$circle-check</v-icon>
            {{ item.exported_count }} / {{ item.batch_item_count }}
          </div>
          <div
              v-else
              class="gLabel gLabel--error"
          >
            <v-icon small>$circle-exclamation</v-icon>
            Ne
          </div>
        </template>
        <template #[`item.boss_voucher_send_count`]="{item}">
          <div
              v-if="item.boss_voucher_send_count"
              class="gLabel gLabel--success"
          >
            <v-icon small>$circle-check</v-icon>
            {{ item.boss_voucher_send_count }} / {{ item.batch_item_count }}
          </div>
          <div
              v-else
              class="gLabel gLabel--error"
          >
            <v-icon small>$circle-exclamation</v-icon>
            Ne
          </div>
        </template>


        <template #[`item.type`]="{ item }">
          <span>{{ formattedDate(item.x_created) }}</span>
        </template>
        <template #[`item.x_created`]="{ item }">
          <span>{{ formattedDate(item.x_created) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <div class="vDataTable__btnsWrapper">
            <v-btn
                v-if="item.deleted"
                class="primary mbtn--history"
                small
                @click="showHistory(item)"
            >
              Historie
            </v-btn>
            <v-btn v-else
                   class="primary"
                   small
                   @click="showReview(item)"
            >
              Zpracovat
            </v-btn>
            <v-btn
                :disabled="!item.reviewed || item.type_batch.identifier === 'zlomky'"
                class="primary"
                small
                :loading="item.loading"
                @click="openExportToBoss(item.id)"
            >
              Založit
            </v-btn>

          </div>
        </template>
        <template #no-data>
          Žádná data...
        </template>
        <template #loading>
          Načítání dat...
        </template>
      </v-data-table>

      <dialog-batch-history :id="selectedId" :show="historyDialog" @close="onHistoryClose()"
                            @update="onHistoryUpdate()"></dialog-batch-history>
    </v-card>
    <v-dialog
        v-model="modalAdd"
        activator="parent"
        width="auto"
    >
      <batch-create-list @crate="handleCrate()"
                         @close="modalAdd=false"
                         :batch="batch"
      ></batch-create-list>
    </v-dialog>
  </div>

</template>
<script>

import {batchStates, fetchBatchList, fetchBatchTypeList} from "./api";
import BatchCreateList from "@/components/batch/BatchCreateList.vue";
import {formattedDate} from "@/utils/formatters";
import DatePicker from "@/components/utils/DatePicker.vue";
import DialogBatchMove from "@/components/batch/DialogBatchMove.vue";
import {fetchCommodityPurities, fetchCommodityTypes} from "@/components/newBuyout/api";
import DialogBatchHistory from "@/components/batch/DialogBatchHistory.vue";


export default {
  name: 'BatchList',
  components: {DialogBatchHistory, DialogBatchMove, DatePicker, BatchCreateList},
  props: {},
  computed: {
    params() {
      return {
        page: this.options.page,
        page_size: this.options.itemsPerPage,
        ordering: this.options.sortBy[0],
        desc: this.options.sortDesc[0],
        search: this.search.fulltext,
        date_from: this.search.dateFrom,
        date_to: this.search.dateTo,
        date_buyout: this.search.buyout,
        exported: this.search.exported,
        reviewed: this.search.reviewed,
        boss_voucher_send: this.search.boss_voucher_send,
        state: this.search.state?.order,
        purity: this.search.purity,
        commodity: this.search.commodity,
        batch_type: this.search.batch_type,
      }
    }
  },
  data() {
    return {
      selectedId: null,
      historyDialog: false,
      selected: [],
      expanded: [],
      loading: false,
      states: [],
      batchTypes: [],
      typeCommodityList: [],
      commodityPurities: [],
      search: {
        buyout: "",
        fulltext: "",
        dateFrom: '',
        dateTo: '',
        boss_voucher_send: "",
        exported: "",
        reviewed: "",
        batch_type: "",
        state: "",
        purity: "",
        commodity: "",
      },
      headers: [
        {text: 'Vytvořeno', value: 'x_created', sortable: true, width: 80},
        {text: 'Várka', value: 'identifier', sortable: true},
        {text: 'Hmotnost', value: 'weight', sortable: true},
        {text: 'Typ', value: 'type_batch.description', sortable: false},
        {text: 'Komodita', align: 'start', value: 'type_commodity.description', sortable: false},
        {text: 'Ryzost', align: 'start', value: 'commodity_purity.purity', sortable: false},
        {text: 'Poznámka', value: 'note', sortable: false},
        {text: 'Stav', value: 'last_state_description', sortable: false},
        {text: 'Dokončeno', value: 'reviewed', sortable: false},
        {text: 'Založeno', value: 'exported', sortable: false},
        {text: 'Doklad', value: 'boss_voucher_send_count', sortable: false},
        {text: '', value: 'actions', sortable: false, width: 240},
      ],
      grid: {
        loading: false,
        itemsPerPageOptions: [50, 100, 200],
        defaultPageSize: 100,
        count: 0,
        next: null,
        previous: null,
      },
      options: {},
      batch: {
        id: "", reviewed: false
      },
      batches: [],
      modalAdd: false,
      showFilters: false
    }
  },
  async mounted() {
    await this.getData()
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true
    },
    search: {
      handler() {
        this.getData()
      },
      deep: true
    },
  },
  methods: {
    row_classes(item) {
      if (item.deleted)
          //return "test"
        return "is-disabled"
      else
        return ""
    },
    openExportToBoss(id) {
      const routeData = this.$router.resolve({
        name: 'BatchSendToBoss',
        params: {id: id}
      })
      window.open(routeData.href, '_blank');
    },
    formattedDate,
    showReview(item) {
      const routeData = this.$router.resolve({
        name: 'BatchReview',
        params: {id: item.id, state: item.last_state_order ? item.last_state_order.toString() : "1"}
      })
      window.open(routeData.href, '_blank');
    },
    showHistory(item) {
      this.selectedId = item.id
      this.historyDialog = true
    },
    onHistoryClose() {
      this.selectedId = ""
      this.historyDialog = false
    },
    onHistoryUpdate() {
      this.selectedId = ""
      this.historyDialog = false
      this.getData()
    },
    handleCrate() {
      this.getData()
      this.modalAdd = false
    },
    async getData() {
      this.grid.loading = true
      await this.fetchBatchStates()
      await this.fetchBatchTypes()
      await this.fetchCommodityTypes()
      await this.fetchCommodityPurities()
      await this.fetchBatchList()
      this.grid.loading = false
    },
    async fetchBatchStates() {
      await batchStates().then(response => {
        this.states = response.data
      })
    },
    async fetchBatchList() {
      await fetchBatchList(this.params).then(response => {
        this.handleResponse(response)
      })
    },
    async fetchBatchTypes() {
      await fetchBatchTypeList().then(response => {
        this.batchTypes = response.data
      })
    },
    async fetchCommodityTypes() {
      await fetchCommodityTypes().then((response) => {
        this.typeCommodityList = response.data
      })
    },
    async fetchCommodityPurities() {
      await fetchCommodityPurities().then((response) => {
        this.commodityPurities = response.data
      })
    },

    handleResponse(response) {
      this.batches = response.data.results
      this.grid.count = response.data.count
      this.grid.next = response.data.next
      this.grid.previous = response.data.previous
    },
    handleSearch() {
      this.options.page = 1
      this.getData()
    },
    handleResetSearch() {
      this.search = {
        fulltext: "",
        dateFrom: '',
        dateTo: '',
      }
    },
  }
}

</script>

<style lang="scss" scoped>
:deep(tr) {
  &.is-disabled {
    > td:not(:has(.mbtn--history)) {
      background: #cccccc;
      opacity: 0.35;
    }

    > td:has(.mbtn--history) {
      background: rgba(#cccccc, 0.35);
    }

    > td > .theme--light.v-data-table {
      background: unset;
    }

    td:not(:has(.v-data-table__expand-icon, .mbtn--history)) {
      pointer-events: none;
    }
  }
}

:deep(th) {
  > .v-icon {
    display: none;
  }

  .v-select {
    .v-select__selections {
      line-height: 18px;
    }

    .v-label,
    .v-select__selection {
      font-size: 13px;
    }

    .v-select__selection {
      margin: 0;
    }

    input {
      flex: 10px;
      max-height: 24px;
    }

    .v-input__icon--clear {
      opacity: 1;

      .v-icon.v-icon {
        font-size: 16px;
      }
    }
  }
}
</style>
