<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600" @keydown.esc="onDialogClose" @click:outside="onDialogClose"
  >
    <template v-slot:activator="{on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-container>
        <v-card-title class="px-2 pt-0 pb-5">
          <v-row>
            <v-col cols="12" md="8" class="pl-6">Historie</v-col>
            <v-col cols="12" md="3" class="pl-6">
              <v-btn class="primary flex-grow-1" @click="onRevertMove" :disabled="isLoading">Obnovit</v-btn>
            </v-col>
          </v-row>

        </v-card-title>
        <v-card-text class="px-2 pb-0">
          <div v-if="isLoading">
            <v-skeleton-loader type="list-item@18"></v-skeleton-loader>
          </div>
          <div v-else>
            <div v-for="(history, i) in histories" :key="i">
              <v-card color="basil"  class="elevation-1">
                <v-row>
                  <v-col cols="12" md="6" class="pl-6">
                    <b class="pl-4">Datum:</b> {{ history.x_created }}
                  </v-col>
                  <v-col cols="12" md="6" class="pl-6">
                    <b class="pl-4">Uživatel:</b> {{ history.user_name }}
                  </v-col>
                  <v-col cols="12" md="6" class="pl-6">
                    <b class="pl-4">Původ:</b>
                    <a :href="getDetailUrl(history?.source)" target="_blank">
                      {{ history?.source?.identifier }}
                    </a>
                  </v-col>
                  <v-col cols="12" md="6" class="pl-6">
                    <b class="pl-4">Destinace:</b>
                    <a :href="getDetailUrl(history?.target)" target="_blank">
                      {{ history?.target?.identifier }}
                    </a>


                  </v-col>
                  <v-col cols="12" md="6" class="pl-6">
                    <b class="pl-4">Váha:</b> {{ history?.weight }}
                  </v-col>
                </v-row>
              </v-card>
              <v-data-table
                  v-if="history"
                  :headers="headers"
                  :items="history.items"
                  class="elevation-0"
                  :hide-default-footer="true"
                  disable-pagination
              >
              </v-data-table>
              <v-divider></v-divider>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap mt-4">
          <v-btn color="primary flex-grow-1" @click="onDialogClose">Zavřit</v-btn>

        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from "@/utils/rules";
import {batchMoveRestore, fetchBatchHistory} from "@/components/batch/api";
import {ACTION_TYPES as AT} from "@/store/action-types";

export default {
  name: 'DialogBatchHistory',
  computed: {
    rules() {
      return rules
    },
  },
  props: {
    id: {required: false, type: String, default: ""},
    show: {type: Boolean, default: false},
  },
  watch: {
    show: {
      handler() {
        this.dialog = this.show
        this.fetchHistory()
      }
    },
  },
  data() {
    return {
      dialog: this.show,
      saving: false,
      isLoading: false,
      histories: [],
      headers: [
        {text: 'Datum', value: 'x_created'},
        {text: 'Původ', value: 'source.identifier'},
        {text: 'Destinace', value: 'target.identifier'},
        {text: "Váha", value: 'batch_item.weight'},
        {text: "Typ", value: 'batch_item.type_jewel.description'},
        {text: "Uživatel", value: 'user_name'},
      ],
    }
  },
  methods: {
    async fetchHistory() {
      this.isLoading = true
      if (!this.id) {
        return []
      }
      await fetchBatchHistory(this.id).then(response => {
        this.histories = response.data
      })
      this.isLoading = false
    },
    async restore() {
      this.saving = true

      this.reset()
    },
    onDialogClose() {
      this.$emit('close')
    },
    getDetailUrl(item) {
      return this.$router.resolve({
        name: 'BatchReview',
        params: {id: item.id, state: item.last_state_order ? item.last_state_order.toString() : "1"},
      }).href;
    },
    onRevertMove() {
      batchMoveRestore(this.id).then(() => {
        this.$emit("update")
        this.$store.dispatch(AT.displayMessage, {
          errorMessage: 'Várka byla úspěšně obnovena',
          many: false,
          isStringMessage: true
        })
        this.saving = false
      })
    }
  }

}

</script>